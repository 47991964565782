import 'babel-polyfill';
import 'core-js/es';

import '@dashboard-experience/mastodon/lib/styles.css';
import './css/main.scss';
import './css/card.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { syncTranslationWithStore, loadTranslations } from 'react-redux-i18n';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { i18n } from '@international/mastodon-i18n';

import ApiStatusBanner from 'components/ApiStatusBanner';
import { trackAnalyticsEvent } from './actions';
import { ANALYTICS_EVENTS } from './lib/analytics';
import resources from './lib/translations/resources';
import Root from './containers/Root';
import configureStore from './store/configureStore';
import translations from './lib/translations';
import { findEnvVar } from './lib/helpers';
import Emitter, { INTL_FORM_VALIDATION_FAILURE } from './lib/utils/emitter';
import {
  ENV,
  REGION_COMPLIANCE_API_BASE,
  SENTRY_ENV,
  SENTRY_RELEASE,
} from './constants';
import initializeSentry from './sentry';
import { rawHtmlRequest } from './actions/helper';

// initialize sentry
if (SENTRY_ENV !== 'development' && SENTRY_RELEASE) {
  initializeSentry();
}

const store = configureStore();

// initialize datadog RUM for production environments
// (Assumes token and app ID are only defined for the appropriate environments)
const DD_RUM_CLIENT_TOKEN = findEnvVar('DD_RUM_CLIENT_TOKEN');
const DD_RUM_APPLICATION_ID = findEnvVar('DD_RUM_APPLICATION_ID');
const DD_RUM_SITE = findEnvVar('DD_RUM_SITE');
if (DD_RUM_CLIENT_TOKEN && DD_RUM_APPLICATION_ID && DD_RUM_SITE) {
  const datadogRumInit = () => {
    datadogRum.init({
      applicationId: DD_RUM_APPLICATION_ID,
      clientToken: DD_RUM_CLIENT_TOKEN,
      env: ENV,
      service: 'apply',
      site: DD_RUM_SITE,
      sessionSampleRate: 20,
      sessionReplaySampleRate: 100, // if not included, the default is 100
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
    });
  };
  datadogRum.onReady(datadogRumInit);

  datadogLogs.init({
    clientToken: DD_RUM_CLIENT_TOKEN,
    site: DD_RUM_SITE,
    service: 'apply',
    env: ENV,
    forwardErrorsToLogs: true,
  });
}
// for RUM, try to mark page views which render while inactive / not visible / hidden
window.addEventListener('load', () => {
  datadogRum.addRumGlobalContext('view', {
    // old/MS browsers may not support document.hidden.  If it's not supported, then we don't know
    // for sure the tab was or wasn't hidden, so consider it to have been hidden.
    window_hidden: document.hidden === undefined || document.hidden,
  });
});

// Sync and load internationalizations
syncTranslationWithStore(store);
store.dispatch(loadTranslations(translations));
i18n.init({ resources: resources() });

// This is preparatory to loading files from RCS for international welcome pages
const welcomeCssPath = `welcome_page?css=true`;
const welcomeCssUrl = `${REGION_COMPLIANCE_API_BASE}/${welcomeCssPath}`;
const handleCssResponse = data => {
  const container = document.createElement('template');
  container.innerHTML = data;
  const styleNode = container.content.firstElementChild.cloneNode(true);
  document.querySelector('head').appendChild(styleNode);
};

rawHtmlRequest(welcomeCssUrl, handleCssResponse);

Emitter.addListener(INTL_FORM_VALIDATION_FAILURE, () => {
  store.dispatch(trackAnalyticsEvent(ANALYTICS_EVENTS.VALIDATION_ERROR));
});

// TODO: .mastodon-id-container should be replaced with #mastodon when
// mastodon contains all base styles
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <CompatRouter>
        <div className='mastodon-id-container'>
          <ApiStatusBanner />
          <Route path='/' component={Root} />
        </div>
      </CompatRouter>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root') || document.createElement('div'), // the latter is only used in the test env
);

export default store;
